import { trpc } from '../../../trpc';
import { getBackendConfig } from '../../../utils/BackendInfrastructure';
import { VaStatusChip } from '../../general/VaStatusChip';
import { Alert, Box, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { type GetVeranstaltungByIdOutput } from 'dtos';
import { useMemo } from 'react';
import { experteName } from 'utils/experteUtils';

type RecentVeranstaltungenProps = {
  readonly themaId: number;
};

const SkeletonRow: React.FC = () => (
  <TableRow>
    <TableCell component="th" scope="row">
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      {' '}
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      {' '}
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      {' '}
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      <Skeleton />
    </TableCell>
  </TableRow>
);

const navigateAsync = async (vaID: number): Promise<void> => {
  const appConfig = await getBackendConfig();
  if (appConfig.success) {
    // open url in new tab
    window.open(`https://${appConfig.config.VivaAppURL}/veranstaltungen/${vaID}`, '_blank');
  }
};

type VeranstaltungsRowProps = { readonly veranstaltung: GetVeranstaltungByIdOutput };

const VeranstaltungsRow: React.FC<VeranstaltungsRowProps> = (props: VeranstaltungsRowProps) => {
  const { veranstaltung } = props;

  const startFmt = veranstaltung.ablauf.at(0)?.startDateTime.toLocaleString() ?? '';
  const endFmt = veranstaltung.ablauf.at(-1)?.endeDateTime.toLocaleString() ?? '';

  const allRoomKuerzel = useMemo(() => veranstaltung.raeume.map((raum) => raum.kuerzel).join(', '), [veranstaltung]);
  const allExperten = useMemo(() => veranstaltung.experteBlockungen.map((experteBlockung) => experteName(experteBlockung.experte)).join(', '), [veranstaltung]);
  return (
    <TableRow onClick={async () => veranstaltung.sapid && (await navigateAsync(veranstaltung.sapid))}>
      <TableCell component="th" scope="row">
        {startFmt} - {endFmt}
      </TableCell>
      <TableCell align="right">{veranstaltung.sapid ?? ' - '}</TableCell>
      <TableCell align="right">
        <VaStatusChip vivaStatus={veranstaltung.vivaStatus} />
      </TableCell>
      <TableCell align="right">unbekannt</TableCell>
      <TableCell align="right">
        {veranstaltung.ortKuerzel} - {allRoomKuerzel}
      </TableCell>
      <TableCell align="right">{allExperten}</TableCell>
      <TableCell align="right">
        <Stack direction="column">
          <Typography variant="body2">5 Gebucht</Typography>
          <Typography variant="body2">2 verfügbar</Typography>
          <Typography variant="body2">0 Warteschlange</Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export const RecentVeranstaltungen: React.FC<RecentVeranstaltungenProps> = (props: RecentVeranstaltungenProps) => {
  const { themaId } = props;
  const veranstaltungenForThema = trpc.veranstaltung.findByThemaId.useQuery({ themaId });

  return (
    <Box>
      {veranstaltungenForThema.isError ? (
        <Alert sx={{ paddingTop: 2 }} severity="error">
          Es trat ein Fehler beim Laden der Veranstaltungen auf
        </Alert>
      ) : (
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Datum</TableCell>
                  <TableCell align="right">E-ID</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Region</TableCell>
                  <TableCell align="right">Hotel/Raum</TableCell>
                  <TableCell align="right">Trainer</TableCell>
                  <TableCell align="right">Teilnehmer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {veranstaltungenForThema.isLoading && Array.from(Array.from({ length: 5 }).keys()).map((idx) => <SkeletonRow key={idx} />)}
                {veranstaltungenForThema.isSuccess && veranstaltungenForThema.data.slice(0, 4).map((vas) => <VeranstaltungsRow key={vas.sapid} veranstaltung={vas} />)}
              </TableBody>
            </Table>
          </TableContainer>
          {veranstaltungenForThema.isSuccess && veranstaltungenForThema.data.length === 0 && (
            <Box sx={{ paddingTop: 2 }}>
              <Alert severity="info">Es wurden keine Veranstaltungen gefunden</Alert>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
