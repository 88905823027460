import { type CalendarEvent, isExperteEvent } from '../helperClasses';
import { Box, Button, type ButtonProps, CircularProgress, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { experteBlockungQuelle, experteBlockungsType, resourcenBlockungTypes } from 'dtos';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import colorPalette from 'theme/colorPalette';
import { trpc } from 'trpc';
import { getBackendConfig } from 'utils/BackendInfrastructure';
import { formatTimeRangeForDate } from 'utils/dateFormat';
import { z } from 'zod';

type SchedulerEventProps = {
  readonly event: CalendarEvent;
};

type VivaEventProps = {
  readonly calendarEvent: CalendarEvent;
};

const eventColor = (event: CalendarEvent): string => {
  if (event.isCurrentSelection) {
    return colorPalette.primary.main;
  }

  if (event.isOldSelection) {
    return colorPalette.monochrome.grey40;
  }

  return event.data.isBookmarked ? colorPalette.orange.main : colorPalette.colors.crimson;
};

type EventButtonWithLinkProps = Pick<ButtonProps, 'children'> & { readonly eventColor?: string; readonly themaSapId?: number | null; readonly veranstaltungId?: string | null };

const EventButtonWithLink: React.FC<EventButtonWithLinkProps> = (props: EventButtonWithLinkProps) => (
  <Button
    component={RouterLink}
    to={`/thema/${props.themaSapId}/veranstaltung/${props.veranstaltungId}`}
    target="_blank"
    variant="contained"
    size="small"
    sx={{ borderRadius: 1, width: '100%', minHeight: '1.5rem', color: 'white !important', backgroundColor: props.eventColor ?? '' }}
  >
    {props.children}
  </Button>
);

type EventButtonProps = Pick<ButtonProps, 'children'> & { readonly eventColor?: string };

const EventButton: React.FC<EventButtonProps> = (props: EventButtonProps) => (
  <Button variant="contained" size="small" sx={{ borderRadius: 1, width: '100%', minHeight: '1.5rem', color: 'white !important', backgroundColor: props.eventColor ?? '' }}>
    {props.children}
  </Button>
);

type EventButtonWithGotoProps = Pick<ButtonProps, 'onClick' | 'children'> & { readonly eventColor?: string };

const EventButtonWithGoto: React.FC<EventButtonWithGotoProps> = (props: EventButtonWithGotoProps) => (
  <Button
    onClick={props.onClick}
    variant="contained"
    size="small"
    sx={{ borderRadius: 1, width: '100%', minHeight: '1.5rem', color: 'white !important', backgroundColor: props.eventColor ?? '' }}
  >
    {props.children}
  </Button>
);

const veranstaltungQueryConfig = {
  retryOnMount: false,
  retry: false,
  refetchOnWindowFocus: false,
};

const VivaEvent: React.FC<VivaEventProps> = ({ calendarEvent }: VivaEventProps) => {
  const data = calendarEvent.data;
  const color = eventColor(calendarEvent);

  const vaHoverInfoQuery = trpc.veranstaltung.findHoverInfoById.useQuery({ id: Number(data.terminId) }, veranstaltungQueryConfig);
  const vaProduktIdQuery = trpc.veranstaltung.findUrlInfoById.useQuery({ id: Number(data.terminId) }, veranstaltungQueryConfig);

  if (vaHoverInfoQuery.isLoading || vaProduktIdQuery.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Tooltip
      title={
        vaHoverInfoQuery.isSuccess ? (
          <Stack>
            <Typography variant="body2">{vaHoverInfoQuery.data.title}</Typography>
            <Typography variant="body2">SapId: {vaHoverInfoQuery.data.sapId ?? '-'}</Typography>
            <Typography variant="body2">Geschäftsbereich: {vaHoverInfoQuery.data.geschaeftsbereich}</Typography>
            <Typography variant="body2">Ablaufzeiten:</Typography>
            {data.ablauf.map(({ start, end }) => (
              <Typography key={Number(start)} variant="body2" paddingLeft={1}>
                {formatTimeRangeForDate(start, end)}
              </Typography>
            ))}
          </Stack>
        ) : (
          <Typography>Fehler beim laden der Veranstaltung</Typography>
        )
      }
    >
      <Box>
        <EventButtonWithLink eventColor={color} themaSapId={vaProduktIdQuery.data?.produktid} veranstaltungId={data.terminId}>
          {vaHoverInfoQuery.isSuccess ? (
            <Stack>
              <Typography variant="body2" textAlign="left">
                {vaHoverInfoQuery.data.buchungsnummer}
              </Typography>
              <Typography variant="caption" fontSize=".5rem">
                {vaHoverInfoQuery.data.ortKuerzel}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body2">{data.isBookmarked ? 'Blockung' : 'Buchung'}</Typography>
          )}
        </EventButtonWithLink>
      </Box>
    </Tooltip>
  );
};

type VivaEventStandortplanungProps = {
  readonly calendarSlotEvent: Extract<CalendarEvent, { type: typeof resourcenBlockungTypes.SLOT }>;
};

const VivaEventStandortplanung: React.FC<VivaEventStandortplanungProps> = ({ calendarSlotEvent }: VivaEventStandortplanungProps) => {
  const data = calendarSlotEvent.data;
  const color = eventColor(calendarSlotEvent);

  const vaHoverInfoQuery = trpc.veranstaltung.findHoverInfoById.useQuery({ id: Number(data.terminId) }, veranstaltungQueryConfig);
  const vaProduktIdQuery = trpc.veranstaltung.findUrlInfoById.useQuery({ id: Number(data.terminId) }, veranstaltungQueryConfig);

  if (vaHoverInfoQuery.isLoading || vaProduktIdQuery.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Tooltip
      title={
        vaHoverInfoQuery.isSuccess ? (
          <Stack>
            <Typography variant="body2">{vaHoverInfoQuery.data.title}</Typography>
            <Typography variant="body2">SapId: {vaHoverInfoQuery.data.sapId ?? 'noch nicht an SAP übermittelt'}</Typography>
            <Typography variant="body2">Geschäftsbereich: {vaHoverInfoQuery.data.geschaeftsbereich}</Typography>
            {data.ablauf.map(({ start, end }) => (
              <Typography key={Number(start)} variant="body2" paddingLeft={1}>
                {formatTimeRangeForDate(start, end)}
              </Typography>
            ))}
            <Divider />
            <Typography variant="body2">Gruppenraum: {data.gruppenraum ? 'Ja' : 'Nein'}</Typography>
            <Typography variant="body2">Hotelqualifikation: {data.hotelqualifikationen}</Typography>
            <Typography variant="body2">Raumgröße: {data.raumgroesse}</Typography>
          </Stack>
        ) : (
          <Typography>Fehler beim laden der Veranstaltung</Typography>
        )
      }
    >
      <Box>
        <EventButtonWithLink eventColor={color} themaSapId={vaProduktIdQuery.data?.produktid} veranstaltungId={data.terminId}>
          {vaHoverInfoQuery.isSuccess ? (
            <Stack>
              <Typography variant="body2" textAlign="left">
                {vaHoverInfoQuery.data.buchungsnummer}
              </Typography>
              <Typography variant="caption" fontSize=".5rem">
                {data.standortName}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body2">unbekannte Blockung</Typography>
          )}
        </EventButtonWithLink>
      </Box>
    </Tooltip>
  );
};

export const DisplayCalendarEvent: React.FC<SchedulerEventProps> = ({ event }: SchedulerEventProps) => {
  const blockingData = event.data;
  const color = eventColor(event);

  const [hiveUrl, setHiveUrl] = useState('');
  useEffect(() => {
    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (config.success) {
        setHiveUrl(config.config.HiveAppUrl ?? '');
      }
    };

    void setUrl();
  }, []);

  if (event.isCurrentSelection) {
    return (
      <EventButton eventColor={color}>
        <Typography variant="body2">Ausgewählt</Typography>
      </EventButton>
    );
  }

  if (event.isOldSelection) {
    return (
      <EventButton eventColor={color}>
        <Typography variant="body2">bisherige Auswahl</Typography>
      </EventButton>
    );
  }

  if (event.type === resourcenBlockungTypes.SLOT) {
    return <VivaEventStandortplanung calendarSlotEvent={event} />;
  }

  if (blockingData.blockungsTyp !== experteBlockungsType.EXTERN && blockingData.quelle === experteBlockungQuelle.VAMOS) {
    return <VivaEvent calendarEvent={event} />;
  }

  const gotoHive = (): void => {
    let link = '';
    if (isExperteEvent(event)) {
      const id = z.coerce.number().parse(event.dataId);
      link = `${hiveUrl}/experten/${id}/auslastung`;
    }

    if (!link) {
      return;
    }

    window.open(link, '_blank');
  };

  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant="body2">TerminId: {blockingData.terminId}</Typography>
          <Typography variant="body2">Start: {event.start.toLocaleString()}</Typography>
          <Typography variant="body2">Ende: {event.end.toLocaleString()}</Typography>
        </Stack>
      }
    >
      <EventButtonWithGoto eventColor={color} onClick={gotoHive}>
        <Stack>
          <Typography>{blockingData.division ?? 'kein Geschäftsbereich'}</Typography>
          <Typography variant="body2">{blockingData.quelle}</Typography>
        </Stack>
      </EventButtonWithGoto>
    </Tooltip>
  );
};
