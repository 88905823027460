import { CalendarExperteEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { endOfDay, max, min, startOfDay } from 'date-fns';
import { type ExperteFromResourcesRouter, resourcenBlockungTypes } from 'dtos';
import { type TerminInfo } from 'utils/TerminInfo';

export class CalendarExperteResource extends AbstractCalendarResource {
  public readonly type = resourcenBlockungTypes.EXPERTE;

  public readonly id: `t-${string}`;

  public name: string;

  public readonly data: ExperteFromResourcesRouter;

  public constructor(terminInfo: TerminInfo, data: ExperteFromResourcesRouter, year: number) {
    super(terminInfo);
    this.id = `t-${data.experteSapId}`;
    const workloadSelectedYear = data.workload.find((wl) => wl.year === year);
    const workloadSelectedYearString = workloadSelectedYear ? ` / (${Math.round(workloadSelectedYear.sumDays)} / ${workloadSelectedYear.thresholdRed})` : ' / ( - / - )';
    this.name = `${data.firstname} ${data.lastname}${workloadSelectedYearString}`;
    this.data = data;
  }

  public setName(name: string) {
    this.name = name;
  }

  public toEvents(): CalendarExperteEvent[] {
    const events: CalendarExperteEvent[] = [];

    for (const blockung of this.data.blockings) {
      const start = blockung.startTime;
      const end = blockung.endTime;

      const event = events.find((ev) => ev.data.terminId === blockung.veranstaltungId);
      if (event) {
        event.start = min([event.start, startOfDay(start)]);
        event.end = max([event.end, endOfDay(end)]);
        event.data.ablauf.push({ start, end });
      } else {
        const newEvent = new CalendarExperteEvent(start, end, this, {
          ablauf: [],
          isBookmarked: blockung.isBookmarked,
          division: blockung.division,
          quelle: blockung.quelle,
          terminId: blockung.veranstaltungId,
          blockungsTyp: blockung.blockungsTyp,
        });
        const isOldSelection = blockung.veranstaltungId === String(this.terminInfo.id) || blockung.veranstaltungId === String(this.terminInfo.sapid);
        newEvent.isOldSelection = isOldSelection;

        events.push(newEvent);
      }
    }

    return events;
  }

  public getDataId(): number {
    return this.data.experteSapId;
  }

  public createEvent(start: Date, end: Date): CalendarExperteEvent {
    const event = new CalendarExperteEvent(start, end, this);
    return event;
  }
}
