import { vivaUserScopesArraySchema } from 'dtos';
import { z, ZodIssueCode } from 'zod';

const sessionStorageKeys = {
  X_VIVA_ADMIN_SCOPES: 'X-Viva-Admin-Scopes',
  LATEST_X_VIVA_REQUEST_ID: 'latest-X-Viva-Request-Id',
} as const;

const xVivaAdminScopesStorageItemSchema = z.object({
  key: z.literal(sessionStorageKeys.X_VIVA_ADMIN_SCOPES),
  value: z.string().transform((stringFromStorage, context) => {
    let objectFromStorage;
    try {
      objectFromStorage = JSON.parse(stringFromStorage);
    } catch {
      context.addIssue({
        code: ZodIssueCode.invalid_type,
        expected: 'object',
        received: 'unknown',
        message: '[xVivaAdminScopesStorageItemSchema] JSON.parse failed.',
      });

      return z.NEVER;
    }

    return vivaUserScopesArraySchema.parse(objectFromStorage);
  }),
});

type XVivaAdminScopesStorageItem = z.output<typeof xVivaAdminScopesStorageItemSchema>;

const latestXVivaRequestIdStorageItemSchema = z.object({
  key: z.literal(sessionStorageKeys.LATEST_X_VIVA_REQUEST_ID),
  value: z.string().uuid(),
});

type LatestXVivaRequestIdStorageItem = z.output<typeof latestXVivaRequestIdStorageItemSchema>;

const sessionStorageItemSchema = xVivaAdminScopesStorageItemSchema.or(latestXVivaRequestIdStorageItemSchema);

type SessionStorageItem = z.output<typeof sessionStorageItemSchema>;

function getItemFromSessionStorage(key: XVivaAdminScopesStorageItem['key']): XVivaAdminScopesStorageItem['value'] | null;
function getItemFromSessionStorage(key: LatestXVivaRequestIdStorageItem['key']): LatestXVivaRequestIdStorageItem['value'] | null;
function getItemFromSessionStorage<T extends SessionStorageItem>(key: T['key']): T['value'] | null {
  const valueFromStorage = sessionStorage.getItem(key);

  if (valueFromStorage === null) {
    return null;
  }

  return sessionStorageItemSchema.parse({ key, value: valueFromStorage }).value;
}

function setItemToSessionStorage(key: XVivaAdminScopesStorageItem['key'], value: XVivaAdminScopesStorageItem['value']): void;
function setItemToSessionStorage(key: LatestXVivaRequestIdStorageItem['key'], value: LatestXVivaRequestIdStorageItem['value']): void;
function setItemToSessionStorage<T extends SessionStorageItem>(key: T['key'], value: T['value']): void {
  const transformedValue: string = typeof value === 'string' ? value : JSON.stringify(value);

  sessionStorageItemSchema.parse({ key, value: transformedValue });

  sessionStorage.setItem(key, transformedValue);
}

function removeItemFromSessionStorage(key: SessionStorageItem['key']): void {
  sessionStorage.removeItem(key);
}

export { getItemFromSessionStorage, removeItemFromSessionStorage, setItemToSessionStorage };
